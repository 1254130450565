html {
  overflow-x: hidden;
  margin-right: calc(-1 * (100vw - 100%));
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.btn-bluelight,
.btn-bluelight:hover,
.btn-bluelight:active,
.btn-bluelight:visited {
  background-color: #42cdc8 !important;
  color: #fff !important;
}

.btn-bluemedium,
.btn-bluemedium:hover,
.btn-bluemedium:active,
.btn-bluemedium:visited {
  background-color: #9bfdf8 !important;
  color: #fff !important;
}

.box-scroll {
  max-height: 40vh;
  overflow-y: auto;
}

.box-scroll::-webkit-scrollbar-track {
  margin-top: 10px;
  border-radius: 10px;
  background-color: transparent;
}

.box-scroll::-webkit-scrollbar {
  width: 0.5vw;
  background-color: transparent;
}

.box-scroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.2);
}

.box-scroll::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.3);
}

.box-scroll {
  scrollbar-width: thin;
}

.overflowX-scroll {
  max-width: 100%;
  overflow-x: auto;
}

.overflowX::-webkit-scrollbar-track {
  margin-top: 10px;
  border-radius: 10px;
  background-color: transparent;
}

.overflowX::-webkit-scrollbar {
  width: 0.5vw;
  height: 0.5vw;
  background-color: transparent;
}

.overflowX::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.2);
}

.overflowX::-webkit-scrollbar-thumb:hover {
  border-radius: 10px;
  background-color: rgba(57, 57, 57, 0.3);
}

.overflowX {
  scrollbar-width: thin;
}

.panel-info {
  border: 1px solid #5bc0de;
  border-radius: 2px;
}

.panel-heading {
  background-color: #5bc0de;
  color: white;
  padding: 0.5rem;
}

.panel-body {
  padding: 0.5rem;
}
