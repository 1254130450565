/* style editor like the displayed sections */
.ql-editor p {
  margin: inherit !important;
  padding: inherit !important;
}
.ql-container {
  font-family: inherit !important;
  font-size: inherit !important;
}

/* address tool item */
.ql-address:after {
  content: '\2691';
}

/* default width is too large */
.dropdown-menu {
  min-width: auto !important;
}
