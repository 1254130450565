img {
  width: 100%;
}

.tum-blue {
  background-color: #3070b3;
}

.tum-logo {
  background-color: #226bb6;
}

.tum-black {
  background-color: #333333;
}

.contract-red {
  background-color: #ff8080 !important;
}

.contract-green {
  background-color: #99e699 !important;
}

.contract-blue {
  background-color: #80b3ff !important;
}

.footer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
}

.main-wrapper {
  position: relative;
  min-height: 100vh;
}

.center-main-inner {
  padding-bottom: 5rem;
}

.landing {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  margin-top: -24px;
  margin-bottom: -50px;
}

.landing-inner {
  padding-top: 80px;
}

.dark-overlay {
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.card-form {
  opacity: 0.9;
}

.latest-profiles-img {
  width: 40px;
  height: 40px;
}

.form-control::placeholder {
  color: #3070b3 !important;
}

/* Table */

th .order-4:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

th .order-4:after {
  content: '\2193';
  opacity: 0.4;
}

th .caret-4-asc:before {
  margin-left: 3.5px;
  content: '\2191';
}

th .caret-4-asc:after {
  content: '\2193';
  opacity: 0.4;
}

th .caret-4-desc:before {
  margin-left: 3.5px;
  content: '\2191';
  opacity: 0.4;
}

th .caret-4-desc:after {
  content: '\2193';
}

.form-group {
  margin-bottom: 1rem;
}

.btn-block {
  display: block;
  width: 100%;
  border: none;
  padding: 14px 28px;
  font-size: 16px;
  cursor: pointer;
  text-align: center;
}

.ml-auto {
  margin-left: auto;
}

.float-right {
  float: right;
}

.btn-info,
.btn-info:hover,
.btn-info:active,
.btn-info:visited {
  background-color: #17a2b8 !important;
  color: #fff !important;
}

.btn:focus,
.btn:active {
  outline: none !important;
  box-shadow: none;
}

.navbar,
.footer {
  --bs-dark-rgb: 52, 58, 64;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:active,
.btn-dark:visited {
  background-color: #343a40 !important;
  border-color: #343a40 !important;
  color: #fff !important;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit !important;
  border-style: solid !important;
}

.accordion {
  max-width: 100%;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f3f3f3;
  font-weight: bold;
}

.accordion-title:hover {
  background-color: #e7e6e6;
}

.accordion-title,
.accordion-content {
  padding: 1rem;
}

.accordion-content {
  background-color: #fafafa;
}

/**
 * Main wrapper
 */
.select-search {
  width: 100%;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: '';
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 0.5rem;
  width: 6px;
  height: 6px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 50px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid #ced4da;
  /* box-shadow: 0 .0625rem .125rem rgba(0, 0, 0, 0.15); */
  border-radius: 0.5rem;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 1.25rem;
  color: #212529;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 40px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  position: static !important;
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding-left: 0;
  padding-right: 0;
  max-height: 12rem;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: 'Noto Sans', sans-serif;
  font-size: 18px;
  color: #495057;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: rgba(76, 75, 75, 0.785);
  border-radius: 3px;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: #306fb375;
  border-radius: 3px;
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #306fb3ba;
  border-radius: 3px;
  color: #fff;
}

.select-search__option:disabled {
  cursor: not-allowed;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 18px;
  color: #495057;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

.select-search.is-disabled .select-search__input:hover {
  cursor: not-allowed;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple) .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  /* border-color: #2FCC8B; */
  border-color: gray;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 200px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
}
.grid-item {
  padding: 4px;
}
.grid-container-3 {
  display: grid;
  grid-template-columns: auto auto auto;
}

.mydropdowncss:hover .dropdown-menu {
  display: block;
  margin-top: 0;
  width: 16rem;
}

/**
* MultiList
*/
.error-border {
  border: 1px solid #dc3545;
  border-radius: 0.5rem;
}

.custom-alert {
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: block;
  opacity: 1;
  z-index: 1000;
  max-width: 300px;
  transition: opacity 0.5s ease-out;
}

/* .custom-alert.show {
  display: block;
  opacity: 1;
} */

/* .custom-alert-success {
  background-color: #28a745;
  color: #fff;
} */
